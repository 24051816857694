import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import { StrongWhite } from "../../../components/Textstyles/Styles";

export const frontmatter = {
  title: "Día 65",
  week: "Semana 10",
  day: "17",
  month: "may",
  monthNumber: "05",
  date: "2020-05-17",
  path: "/cronologia/semana-10#dia-17-may",
};
const Day65 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 993 nuevos positivos y 87 personas fallecidas. Permanecen
          hospitalizadas 125.110 personas, 327 más que el día anterior, y 3.166
          reciben el alta. El número de casos ingresados en la UCI asciende a
          11.528, lo que supone un aumento de 65 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados del 53,9 %.
        </ModText>
        <ModDailyHighlight>
          <StrongWhite>España</StrongWhite> baja de los 100 fallecidos al día
        </ModDailyHighlight>

        <ModText>
          El Ministerio de Sanidad, por su parte, está trabajando en la
          elaboración de una nueva orden para extender las medidas aplicadas en
          municipios de menos de 5.000 habitantes a los de menos de 10.000
          habitantes.
        </ModText>
        <ModText>
          Desde el inicio de la alerta por SARS-CoV-2 se ha registrado un{" "}
          <strong>22,3% de casos de coronavirus en personal sanitario</strong>:
          el 76% de ellos son mujeres, y la media de edad de es 46 años, siendo
          mayor en hombres que en mujeres (47 vs 46 años).
        </ModText>
        <ModGraph
          name="17-05-ccaa-renave"
          alt="Porcentaje de casos de COVID-19 notificados a la RENAVE que corresponden a personal sanitario"
        />
        <ModGraph
          name="17-05-piramideRenave"
          alt="Distribución por edad y sexo en personal sanitario notificados a la Renave"
        />
        <ModText>
          En cuanto a la gravedad de los casos notificados en personal
          sanitario, un 10,5% ha sido hospitalizado, un 16% ha desarrollado
          neumonía, un 1,2% ha sido admitido en UCI y un 0,1% ha fallecido.
        </ModText>
        <ModText>
          Entre los <strong>casos hospitalizados ingresados en UCI</strong>, hay
          una mayor proporción de hombres, mayor prevalencia de enfermedades de
          base, neumonía y presentación de Síndrome de distrés respiratorio del
          adulto, que entre los hospitalizados sin ingreso en UCI.
        </ModText>
        <ModText>
          El 65% de los profesionales sanitarios han presentado como antecedente
          epidemiológico de riesgo el{" "}
          <strong>contacto con personas con infección respiratoria</strong>, y
          el 69% el contacto estrecho con casos de COVID-19 probable o
          confirmado.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day65;
